<template>
	<div class="section">
		<div class="container">
			<div class="section__wrap">
				<div class="section__cnt">
					<div class="section__head section__head--table">
						<div class="title title--section">Rules / FAQ</div>
					</div>

					<div class="section__main">
						<div class="faq">
							<div class="faq__rules">
								<div class="rules">
									<ul class="rules__list">
										<li class="rules__item" v-for="i in 8">
											<div class="rules__card">
												<div class="rules__head">
													<v-icon name="info-circle"></v-icon>
													<span>Rule title {{i}}</span>
												</div>
												<div class="rules__cnt">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab accusamus aperiam atque consectetur delectus deserunt dignissimos dolorem eaque esse, excepturi illo incidunt ipsa ipsam ipsum iste libero minus molestias nisi nulla numquam provident quo saepe tenetur ut veritatis voluptates!</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div class="faq__faqs">
								<ul class="faq__list">
									<li class="faq__item" v-for="faq in faqsList">
										<card-faq :faq="faq"></card-faq>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import faqs from '@/database/faqs.js';
	import CardFaq from './CardFaq';

	export default {
		name: 'faqs-index',
		components:{
			CardFaq
		},
		data(){
			return{
				faqsList: faqs.list
			}
		}
	};
</script>

<style scoped lang=scss>
	.faq{
		display: flex;
		flex-wrap: wrap;
		&__rules{
			width: 45%;
			padding-right: 80px;
		}
		&__faqs{
			width: 55%;
		}
		&__item{
			margin-bottom: 40px;
		}
	}

	.rules{
		&__item{
			margin-bottom: 20px;
		}
		&__card{
			background: #DCD2E4;
			padding: 10px;
			border-radius: 10px;
		}
		&__head{
			svg{
				margin-right: 5px;
				fill: $c_alt;
			}
			span{
				font-family: $f_alt;
				color: $c_alt;
			}
		}
		&__cnt{
			font-size: 14px;
			line-height: 1.5em;
		}
	}
</style>
