<template>
	<div class="faqitem" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
		<div class="faqitem__ques">
			<div class="faqitem__icon">
				<img :src="require('@img/faq_icon2.webp')" alt="">
			</div>
			<div class="faqitem__card">
				<div class="ico__wrap ico__wrap--top">
					<img class="ico ico--type1" :src="require('@img/border_card.webp')" alt="">
					<img class="ico ico--type2" :src="require('@img/border_card.webp')" alt="">
				</div>

				<div class="ico__wrap ico__wrap--bot">
					<img class="ico ico--type3" :src="require('@img/border_card.webp')" alt="">
					<img class="ico ico--type4" :src="require('@img/border_card.webp')" alt="">
				</div>

				<span itemprop="name">{{faq.question['en'] || faq.question}}</span>
			</div>
		</div>

		<div class="faqitem__ans" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
			<div class="faqitem__card faqitem__card--dark">
				<div class="ico__wrap ico__wrap--top">
					<img class="ico ico--type1" :src="require('@img/border_card2.webp')" alt="">
					<img class="ico ico--type2" :src="require('@img/border_card2.webp')" alt="">
				</div>

				<div class="ico__wrap ico__wrap--bot">
					<img class="ico ico--type3" :src="require('@img/border_card2.webp')" alt="">
					<img class="ico ico--type4" :src="require('@img/border_card2.webp')" alt="">
				</div>
				<span itemprop="text">{{faq.answer['en'] || faq.answer}}</span>
			</div>
			<div class="faqitem__icon">
				<img :src="require('@img/faq_icon1.webp')" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'card-faq',
		props: ['faq']
	};
</script>

<style scoped lang=scss>
	.faqitem{
		&.has-sm{
			.faqitem__icon{
				width: 60px;
				height: 60px;
				img{
					height: 35px;
				}
			}
			.faqitem__card{
				font-size: 16px;
				line-height: 20px;
				margin-left: 20px;
				max-width: 100%;
				width: 100%;
				&--dark{
					margin-right: 20px;
					margin-left: 0;
				}
			}
			.faqitem__ans{
				justify-content: flex-start;
			}
		}
		&__ques{
			margin-bottom: 5px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.faqitem__card{
				font-weight: 500;
			}
		}
		&__ans{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			.ico__wrap{
				&:after,
				&:before{
					background: rgba(255,255,255,0.5);
				}
			}
		}
		&__card{
			background: #F3EEF6;
			margin-left: 30px;
			max-width: 450px;
			padding: 20px 30px;
			font-size: 18px;
			line-height: 23px;
			display: flex;
			align-items: center;
			position: relative;
			min-height: 80px;
			margin-left: 10px;
			font-size: 12px;
			&--dark{
				background: #4A3A5F;
				margin-left: 0;
				margin-right: 30px;
				color: $c_main_light;
				margin-right: 10px;
			}
		}
		&__icon{
			flex-shrink: 0;
			width: 80px;
			height: 80px;
			background: $c_main_light;
			box-shadow: 0 1px 8px rgba(0,0,0,0.2);
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			height: 50px;
			img{
				max-width: 30px;
				max-height: 30px;
			}
		}
	}

	.ico{
		position: absolute;
		&--type1{
			left: 3px;
			top: 3px;
		}
		&--type2{
			right: 3px;
			top: 3px;
			transform: rotate(90deg);
		}
		&--type3{
			left: 3px;
			bottom: 3px;
			transform: rotate(-90deg);
		}
		&--type4{
			right: 3px;
			bottom: 3px;
			transform: rotate(180deg);
		}
		&__wrap{
			&--top{
				&:before{
					content: '';
					display: block;
					width: 1px;
					background: rgba(23,16,38, 0.3);
					top: 35px;
					bottom: 35px;
					left: 4px;
					position: absolute;
				}
				&:after{
					content: '';
					display: block;
					width: 1px;
					background: rgba(23,16,38, 0.3);
					top: 35px;
					bottom: 35px;
					right: 4px;
					position: absolute;
				}
			}
			&--bot{
				&:before{
					content: '';
					display: block;
					height: 1px;
					background: rgba(23,16,38, 0.3);
					top: 4px;
					left: 35px;
					right: 35px;
					position: absolute;
				}
				&:after{
					content: '';
					display: block;
					height: 1px;
					background: rgba(23,16,38, 0.3);
					bottom: 5px;
					left: 35px;
					right: 35px;
					position: absolute;
				}
			}
		}
	}
</style>
